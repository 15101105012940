import {Component, Vue} from 'vue-property-decorator';
import {EvaluationType} from "../../shared/model/EvaluationType";
import {ProviderType} from "../../shared/model/ProviderType";
import {ActivityType} from "../../shared/model/ActivityType";
import {County} from "../../shared/model/County";
import {SurveyType} from "../../shared/model/SurveyType";
import {Status} from "../../shared/model/Status";
import {evalApi, globalConfig} from "../../plugins/axios";
import {FiscalYear} from "../../shared/model/FiscalYear";
import {Department} from "../../shared/model/Department";
import {Flag} from "@/shared/model/Flag";
import {SurveyFundingPercent} from "@/shared/model/SurveyFundingPercent";
import {FundingType} from "@/shared/model/FundingType";
import {rules} from "@/shared/model/Rules";

@Component({
    components: {},
})
export default class SurveyFundingPercentForm extends Vue {
    title = "Survey Funding Percent Form"
    maskText = "";
    valid = true;
    fundingType: SurveyFundingPercent = new SurveyFundingPercent();
    menu1 = false;
    menu2 = false;
    evaluationTypes = EvaluationType.values;
    providerTypes = ProviderType.values;
    activityTypes = ActivityType.values;
    counties = County.values;
    surveyTypes: Array<SurveyType> = [];
    fundingTypes: Array<FundingType>= [];
    statuses = Status.values;
    stateFlags = Flag.values;
    fiscalYears = new FiscalYear().values
    endDateString: string = "";
    dueDateString: string = "";
    departments = Department.values.sort();
    fundingTypeIds: any = [];
    surveyTypeIds:any = [];
    requiredRule = rules;

    public created() {
        this.loadFundingTypes();
        this.loadSurveyTypes();
    }

    public loadFundingTypes(): void {
        evalApi.get('/fundingType', globalConfig)
            .then((res: any) => {
                this.fundingTypes = res.data;
                for (let i:number = 0; i <= this.fundingTypes.length; i++) {
                    this.fundingTypeIds.push(this.fundingTypes[i].fundingTypeId)
                }
            })
    }

    formatDate(date: String): String {
        if (!date) return "MM/DD/YYYY";
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
    }

    submitNewSurveyFundingPercent(): void {
        evalApi.post("/surveyFundingPercent/create", {
            fundingTypeId: this.fundingType.fundingTypeId,
            surveyTypeId: this.fundingType.surveyTypeId,
            budgetDepartment: this.fundingType.budgetDepartment,
            federalFiscalYear: this.fundingType.federalFiscalYear,
            startDate: this.fundingType.startDate,
            endDate: this.fundingType.endDate,
            percent: this.fundingType.percent
        }, globalConfig).then(res => {
            //window.location.reload();
            alert("Your fundingType was submitted successfully!")
        }).catch(error => {
            console.log(`An error occurred submitting the fundingType>>>> ${error}`)
        })
    }

    public loadSurveyTypes(): void {
        evalApi.get('/surveyType/all', globalConfig)
            .then((res: any) => {
                this.surveyTypes = res.data;
                for (let i:number = 0; i <= this.surveyTypes.length; i++) {
                    this.surveyTypeIds.push(this.surveyTypes[i].surveyTypeId)
                }
            })
    }
}