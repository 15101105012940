export class SurveyFundingPercent {
    surveyFundingPercentId?: any;
    fundingTypeId?: any;
    surveyTypeId?: any;
    federalFiscalYear?: any;
    percent?: any;
    budgetDepartment?: any;
    insertUid?: any;
    insertDate?: any;
    modifyUid?: any;
    modifyDate?: any;
    startDate?: any;
    endDate?: any;
}
